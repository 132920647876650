import React, { useEffect, useState } from 'react'
import { pure } from 'recompose'
import { dataStore, pageStore } from "state/store-zustand";

import { fetchPlacesDetails } from "vibemap-constants/dist/helpers"

import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'

import EventForm from 'components/forms/addEventFormBasic'

import AuthDialog from "components/account/authDialog"
import Branding from "components/account/branding"

import '../styles/search.scss'
import 'components/forms/form.scss'

const EditEvent = ({ data, location, params,...props }) => {
  // Page context and options
  const pageLoader = usePageLoading()
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const isClient = pageStore((state) => state.isClient)
  const embedded = pageStore((state) => state.embedded)
  const eventID = dataStore((state) => state.eventID)
  const eventCurrent = dataStore((state) => state.eventCurrent)
  const setEventCurrent = dataStore((state) => state.setEventCurrent)

  // Form content and state
  const alreadyLoaded = eventCurrent != null
  const [isLoading, setIsLoading] = useState(!alreadyLoaded)
  const [hasError, setHasError] = useState(false)

  const [clearForm, setClearForm] = useState(false)

  const handleClick = (event, data) => {
    //console.log('handleClick ', event, data)
    if (data.details) {
      setEvent(data.details)
    }
  }

  const handleSubmit = async (event, data) => {
    debugger
    console.log('TODO: handleSubmit ', event, data);
    //setEventCurrent(null)
    //setClearForm(true)
    //setTitle('Thanks for helping us!')
    //setMessage(<p>Vibemap will include this business in our city pages, vibe guides, and other content.</p>)
  }

  if (!isClient) {
    return null
  }

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      const details = await fetchPlacesDetails(id, 'event')
        .catch(e => {
          console.log(`Error with event `, id, e)
          setHasError(true)
        })

      if (details) {
        setEventCurrent(details.data)
        setIsLoading(false)
        const place = details.data?.properties?.hotspots_place
      } else {
        setHasError(true)
        setIsLoading(false)
      }
    }

    if (eventCurrent == null) {
      fetchData(eventID)
    }

  }, [eventID])


  const EventFormMemo = React.memo(EventForm);

  return (
    <>
      <main className={`page add-event ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>

        <Branding />

        <AuthDialog />

        <div className='container'>
            <EventFormMemo
                onSubmitCallback={handleSubmit}
                showSearch={false}
                updateOnly={true} />
        </div>
      </main>
    </>
  )
}

export default pure(EditEvent);